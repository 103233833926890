<template>
  <div class="trivia">
    <!-- <XIcon class="x-icon" @click="goHome" /> -->
    <h1>{{ $t('trivia.headline') }}</h1>
    <!-- <hr class="separator" /> -->
    <div class="start-quiz" :class="{ done: isSubmit }">
      <!-- <p class="question-header">{{ isFrench ? trivia.nameFR : trivia.name }}</p> -->
      <span v-if="isSubmit" class="result-heading"> {{ $t('trivia.yourResult') }} </span>
      <div v-if="!isSubmit && trivia.questions">
        <span class="question-counter">
          Question {{ qNum + 1 }} {{ $t('trivia.of') }} {{ trivia.questions.length }}
        </span>
        <p class="question-header">
          {{ isFrench ? trivia.questions[qNum].questionTextFR : trivia.questions[qNum].questionText }}
        </p>
        <div v-if="trivia.questions[qNum].questionType === 'single_answer'" class="bullets">
          <div v-for="(answer, index) in trivia.questions[qNum].answers" :key="index" class="bullet">
            <GRadio
              v-model="selectedOption"
              :label="isFrench ? answer.answerTextFR : answer.answerText"
              name="choice"
              :value="answer.id"
            />
          </div>
        </div>
        <div v-else class="checkmarks">
          <span class="select-all"> {{ $t('trivia.selectMultiple') }} </span>
          <div v-for="(answer, index) in trivia.questions[qNum].answers" :key="index" class="checkmark">
            <GCheckbox
              type="checkbox"
              :label="isFrench ? answer.answerTextFR : answer.answerText"
              name="ex1"
              :value="answer.id"
              v-model="selectedOption"
            />
          </div>
          <span class="bullet-text">{{ answer }}</span>
        </div>
        <PassportButton
          :disabled="selectedOption === null"
          :label="$t('trivia.submitFinal')"
          class="trivia-button"
          @click="submit"
        />
      </div>
      <div v-if="isSubmit" class="answer">
        <div class="box">
          <span class="stats" v-if="trivia.questions">
            {{ numCorrect }} {{ $t('trivia.of') }} {{ trivia.questions.length }}
          </span>
          <span class="stat-description"> {{ $t('trivia.correctAnswers') }} </span>
        </div>
        <!-- <span v-if="pointsAwarded === 0 && !allowRetakes" class="fail-text">
          Sorry, you did not answer enough questions correctly to earn points.
        </span>
        <div v-if="pointsAwarded === 0 && allowRetakes" class="retake">
          <span class="fail-text"> You did not answer enough questions correctly. Try again to earn points! </span>
          <PassportButton label="Try Again" class="try-again-button" @click="restart" />
        </div> -->
        <hr class="line" />
        <GCollapsible class="results-breakdown" :title="$t('trivia.viewResults')">
          <div v-for="(question, index) in trivia.questions" :key="index">
            <p
              :class="{
                'question-heading': question.isCorrectAnswer,
                'question-heading__error': !question.isCorrectAnswer,
              }"
            >
              Question {{ index + 1 }}
            </p>
            <span> {{ isFrench ? question.questionTextFR : question.questionText }} </span>
            <p
              :class="{
                'answer-heading': question.isCorrectAnswer,
                'answer-heading__error': !question.isCorrectAnswer,
              }"
            >
              {{ $t('trivia.yourAnswer') }}
            </p>
            <div v-if="checkAnswer(question, index)">
              <div class="answer-display">
                <div class="answer-text">
                  <Checkmark class="mark" />
                  <span v-if="question.questionType === 'single_answer'">
                    {{
                      isFrench
                        ? question.answers.find((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                            .answerTextFR
                        : question.answers.find((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                            .answerText
                    }}
                  </span>
                  <span v-else>
                    {{
                      question.answers
                        .filter((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                        .map((answer) => (isFrench ? answer.answerTextFR : answer.answerText))
                        .join(', ')
                    }}
                  </span>
                </div>
                <span class="message">{{ $t('trivia.youAreCorrect') }}</span>
              </div>
            </div>
            <div v-else>
              <div class="answer-display__error">
                <XIcon class="mark__error" />
                <span v-if="question.questionType === 'single_answer'">
                  {{
                    isFrench
                      ? question.answers.find((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                          .answerTextFR
                      : question.answers.find((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                          .answerText
                  }}
                </span>
                <span v-else>
                  {{
                    question.answers
                      .filter((answer) => finalAnswers[index].selectedAnswers.includes(answer.id))
                      .map((answer) => (isFrench ? answer.answerTextFR : answer.answerText))
                      .join(', ')
                  }}
                </span>
              </div>
              <div v-if="showCorrectAnswers" class="correct-answer">
                <p class="answer-heading">{{ $t('trivia.correctAnswer') }}</p>
                <div class="correct-answer-display">
                  <Checkmark class="mark" />
                  <span>{{ correctAnswer(question) }}</span> <br />
                </div>
                <span class="message">{{ $t('trivia.youAreIncorrect') }}</span>
              </div>
            </div>

            <p>{{ isFrench ? question.funFactFR : question.funFact }}</p>

            <hr class="results-separator" />
          </div>
        </GCollapsible>
        <!-- <Points v-if="pointsAwarded !== 0 && !quizAlreadyTaken" :points="pointsAwarded" />
        <Points
          v-if="pointsAwarded !== 0 && quizAlreadyTaken"
          :points="pointsAwarded"
          :attempted="'You already earned points for this quiz'"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GCollapsible } from '@twentyfourg/grimoire';
import XIcon from '@/assets/icons/x_icon.svg';
import PassportButton from '@/components/PassportButton.vue';
import Checkmark from '@/assets/icons/checkmark.svg';
import { ApiService } from '@/services/api.service';
import GCheckbox from '@/components/GCheckbox.vue';
// import Points from '@/components/Points.vue';
import GRadio from '@/components/GRadio.vue';

export default {
  name: 'Trivia',
  components: {
    XIcon,
    GRadio,
    PassportButton,
    Checkmark,
    GCheckbox,
    // Points,
    GCollapsible,
  },
  data() {
    return {
      quizAlreadyTaken: this.$store.state.targetCompleted,
      selectedOption: '',
      isSubmit: false,
      id: this.$route.params.id,
      trivia: [],
      qNum: 0,
      answer: '',
      numCorrect: '',
      allowRetakes: false,
      requirePassingScore: false,
      showCorrectAnswers: false,
      pointsAwarded: 0,
    };
  },
  mounted() {
    if (!this.$route.query.tid) this.$router.push('/');
    else this.getTrivia();

    // start on last unfinished question
    const total = this.$store.state.numTriviaQuestions;
    if (total !== -1 && this.finalAnswers.length >= total) {
      this.finalAnswers = [];
      this.qNum = 0;
    } else {
      this.qNum = this.finalAnswers.length;
    }
  },
  computed: {
    isFrench() {
      return this.$store.state.locale === 'fr';
    },
    finalAnswers: {
      get() {
        return this.$store.state.trivia;
      },
      set(val) {
        this.$store.commit('setTrivia', val);
      },
    },
  },
  methods: {
    checkAnswer(question, index) {
      if (question.questionType === 'single_answer') {
        if (
          question.answers.find((answer) => answer.isCorrect === true).id ===
          this.finalAnswers[index].selectedAnswers[0]
        ) {
          question.isCorrectAnswer = true;
        }
      } else {
        const correctOptions = question.answers.filter((answer) => answer.isCorrect === true);
        const incorrectOptions = question.answers.filter((answer) => answer.isCorrect === false);
        if (
          correctOptions.every((choice) => this.finalAnswers[index].selectedAnswers.includes(choice.id)) &&
          incorrectOptions.every((choice) => !this.finalAnswers[index].selectedAnswers.includes(choice.id))
        ) {
          question.isCorrectAnswer = true;
        }
      }
      return question.isCorrectAnswer;
    },
    correctAnswer(question) {
      if (question.questionType === 'single_answer') {
        return this.isFrench
          ? question.answers.find((answer) => answer.isCorrect === true).answerTextFR
          : question.answers.find((answer) => answer.isCorrect === true).answerText;
      }
      return question.answers
        .filter((answer) => answer.isCorrect === true)
        .map((answer) => (this.isFrench ? answer.answerTextFR : answer.answerText))
        .join(', ');
    },
    async getTrivia() {
      await ApiService.get(`/quiz/trivia/${this.id}`).then((response) => {
        this.trivia = response.data;
        this.allowRetakes = response.data.allowRetakes;
        this.requirePassingScore = response.data.requirePassingScore;
        this.showCorrectAnswers = response.data.showCorrectAnswers;
      });
      this.trivia.questions.forEach((question) => {
        this.$set(question, 'isCorrectAnswer', false);
      });
      if (this.trivia.questions[this.qNum].questionType === 'single_answer') {
        this.selectedOption = '';
      } else {
        this.selectedOption = [];
      }
      this.$store.commit('setNumTriviaQuestions', this.trivia.questions.length);
    },
    goHome() {
      this.$router.push('/');
    },
    restart() {
      this.$router.go();
    },
    async submit() {
      let questions = {};
      if (this.selectedOption !== '' && this.selectedOption.length > 0) {
        if (Array.isArray(this.selectedOption)) {
          questions = {
            questionId: this.trivia.questions[this.qNum].id,
            selectedAnswers: this.selectedOption,
          };
        } else {
          questions = {
            questionId: this.trivia.questions[this.qNum].id,
            selectedAnswers: [this.selectedOption],
          };
        }
        this.$store.commit('addTrivia', questions);
        // console.log(this.trivia.questions);
        if (this.qNum < this.trivia.questions.length - 1) {
          this.qNum += 1;
          if (this.trivia.questions[this.qNum].questionType === 'single_answer') {
            this.selectedOption = '';
          } else {
            this.selectedOption = [];
          }
        } else {
          this.finishQuiz();
        }
      }
    },
    async finishQuiz() {
      const triviaAnswers = {
        targetId: this.$route.query.tid,
        answers: this.finalAnswers,
      };
      await ApiService.post(`/quiz/trivia/${this.id}/answer`, triviaAnswers).then((res) => {
        this.numCorrect = res.data.numCorrect;
        this.pointsAwarded = res.data.pointsAwarded;
        this.isSubmit = true;
      });
      this.isSubmit = true;
      // console.log(this.finalAnswers);
    },
  },
};
</script>

<style lang="scss" scoped>
.trivia {
  position: relative;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: calc(100svh - 3em - 39px - 40px - 69px * 2);
  // padding: 1em;
  // padding-bottom: calc(3em + 39px);
  // background-image: url(../assets/images/bg_main_repeating@2x.png);
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  .welcome-page {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .trivia-name {
    font-size: 27px;
    font-weight: normal;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: -0.41px;
  }
  .trivia-description {
    margin-top: 1em;
    font-size: 18px;
    text-align: left;
    letter-spacing: -0.27px;
  }
  .start-button {
    height: 50px;
    margin-top: 2em;
    font-size: 16px;
    text-transform: uppercase;
  }
  .start-quiz {
    width: 85%;
  }
  .question-header {
    margin-top: 2em;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    // text-transform: uppercase;
  }
  .question-counter,
  .result-heading {
    font-size: 12px;
    text-transform: uppercase;
  }
  .paragraph {
    margin-top: 2em;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
  }
  .bullets {
    margin-top: 1em;
    font-size: 18px;
    line-height: 2.5;
    text-align: left;
  }
  .answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
  }
  .box {
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 10px;
    margin: auto;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
  }
  .fail-text {
    margin: 2em 0;
  }
  .stats {
    padding: 6px;
    font-size: 34px;
    text-align: left;
  }
  .stat-description {
    padding: 6px;
    margin-top: 15px;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
  }
  .question-heading {
    margin-top: 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    &__error {
      margin-top: 1em;
      font-size: 12px;
      color: $red;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
  .answer-heading {
    margin-top: 2em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    &__error {
      margin-top: 2em;
      font-size: 12px;
      color: $red;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
  .retake {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .try-again-button {
    width: 100%;
    height: 53px;
    margin-bottom: 2em;
    text-transform: uppercase;
  }
  .answer-text {
    margin-bottom: 1em;
  }
  .answer-display {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 0.5em;
    font-size: 18px;
    &__error {
      display: flex;
      width: 80%;
      margin-bottom: 0.5em;
      font-size: 18px;
      color: $red;
    }
  }
  .correct-answer-display {
    display: flex;
    width: 80%;
    margin-bottom: 0.5em;
    font-size: 18px;
    &__error {
      display: flex;
      width: 80%;
      margin-bottom: 0.5em;
      font-size: 18px;
      color: $red;
    }
  }
  .mark {
    width: 15px;
    margin-right: 1em;
    path {
      fill: #222;
      stroke: #222;
    }
    &__error {
      width: 15px;
      margin-right: 1em;
      ::v-deep g {
        stroke: $red;
        stroke-width: 2;
      }
    }
  }

  .x-icon {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 4em;
    height: 1.2em;
    margin-top: 1em;
  }
  .separator {
    width: 6em;
  }
  .trivia-button {
    width: 17.625em;
    height: 3em;
    margin-top: 2em;
    font-family: $head-font-stack;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  .try-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .message {
    font-size: 14px;
  }
  .results-breakdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 8em;
    margin-left: -7px;
    text-align: left;
    border-radius: 10px;
    ::v-deep svg {
      width: 14px;
      fill: #222;
      stroke: #222;
    }
  }
  ::v-deep .title {
    font-family: $head-font-stack;
    font-size: 18px;
  }
  .line {
    width: 100%;
  }
  .results-separator {
    width: 100%;
    margin-top: 2em;
    opacity: 0.2;
  }
  .select-all {
    margin-bottom: 1em;
    font-size: 14px;
    text-align: left;
  }
  .checkmarks {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  ::v-deep .g-checkbox .label.after {
    padding: 10px;
    margin-left: 0.5em;
    font-size: 18px;
  }
}
</style>
